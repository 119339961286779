import { EngagementStatus, Engagement } from "../core/types";

export const isProd = /^ep(-gcr)?.(proserve|gss).aws.dev$/.test(window.location.host);

export function getEvaluatedInProgressStatus(engagement: Engagement): EngagementStatus | undefined {
    if (engagement) {
        if (engagement.stage === EngagementStatus.IN_PROGRESS) {
            if (engagement.hasActiveTimecard === false) {
                return EngagementStatus.STALLED;
            } else {
                return EngagementStatus.IN_PROGRESS;
            }
        } else {
            return engagement.stage as EngagementStatus;
        }
    } else {
        return undefined;
    }
}

export function is12HourTime(time: string) {
    const validationRegex = /(1[012]|0[1-9]):[0-5][0-9]/;
    return validationRegex.test(time);
}

export function convertTo24Hour({ value, timeOfDay }) {
    const time = value.split(':');
    let hour = parseInt(time[0]);
    const minute = time[1];
    if (timeOfDay === 'PM' && hour < 12) {
        hour += 12;
    } else if (timeOfDay === 'AM' && hour === 12) {
        hour = 0;
    }
    return `${hour.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${minute}`;
}

export function convertTo12Hour(value: string) {
    const time = value.split(':');
    let hour = parseInt(time[0]);
    const minute = time[1];
    let timePeriod = 'PM';
    if (hour < 12) {
        timePeriod = 'AM';
        if (hour === 0) {
            hour = 12;
        }
    } else if (hour > 12) {
        hour -= 12;
    }
    return `${hour.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${minute} ${timePeriod}`;
}

export function delay(delayInms) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(2);
      }, delayInms);
    });
  }